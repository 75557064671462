import { Box } from '@mui/material'
import { AppHeader, TitleI18nKey } from './AppHeader'

export const PageWithHeader = ({
  titleI18nKey,
  title,
  mobileLeftContent,
  mobileRightContent,
  children,
}: {
  titleI18nKey?: TitleI18nKey
  title?: string
  mobileLeftContent?: React.ReactNode
  mobileRightContent?: React.ReactNode
  children: React.ReactNode
}) => {
  return (
    <>
      <AppHeader
        titleI18nKey={titleI18nKey}
        title={title}
        mobileLeftContent={mobileLeftContent}
        mobileRightContent={mobileRightContent}
      />

      <Box
        // UsersAppBarの高さ分は上のmargin確保
        marginTop={{ xs: '56px', sm: '64px' }}
        // FooterまたはBottomNavが画面下に来るようにする
        // xsはAppBarとBottomNav、smはAppBarとFooterの高さ分を考慮
        minHeight={{ xs: 'calc(100vh - 112px)', sm: 'calc(100vh - 164px)' }}
        // フッター分の高さを確保
        paddingBottom={{ xs: '56px', sm: '0px' }}
      >
        {children}
      </Box>
    </>
  )
}
