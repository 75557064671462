import { Box, Chip } from '@mui/material'
import { Colors } from '~/libs/mui/theme'

export const UpComingChip = () => {
  return (
    <Box position='absolute' top={0}>
      <Chip
        sx={{
          color: Colors.color0D0D0E,
          backgroundColor: Colors.colorFFEB3B,
          fontSize: '12px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          '& .MuiChip-label': {
            paddingLeft: '4px',
          },
        }}
        label='公開予定'
        size='small'
      />
    </Box>
  )
}
