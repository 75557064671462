import { Home, Search, SettingsOutlined } from '@mui/icons-material'
import { Box, Button, IconButton, SvgIcon, Toolbar } from '@mui/material'
import NextLink from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { useTranslation } from 'react-i18next'
import { SvgAsset } from '~/components/SvgAsset'
import { Colors } from '~/libs/mui/theme'
import { UserLoginState } from '~/recoil/auth'
import { UserAvatar } from '../UserAvatar'

interface PcHeaderContentProps {
  loginState: UserLoginState
  avatarUrl: string
}

export function PcHeaderContent({ loginState, avatarUrl }: PcHeaderContentProps) {
  const { push } = useRouter()
  const pathname = usePathname()
  const { t } = useTranslation()

  return (
    <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'stretch' }}>
      <IconButton
        sx={{
          width: '120px',
          display: { xs: 'none', sm: 'flex' },
          padding: 0,
          mr: 2,
        }}
        LinkComponent={NextLink}
        href={'/'}
      >
        <SvgAsset asset='logo_with_text' sx={{ width: '100%', height: '100%' }} />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <Button
          variant='text'
          startIcon={<Home />}
          sx={{ color: pathname === '/' ? Colors.yellow : Colors.color5A5957 }}
          LinkComponent={NextLink}
          href={'/'}
        >
          {t('home')}
        </Button>
        <Button
          variant='text'
          startIcon={<Search />}
          sx={{
            color: pathname?.startsWith('/search') ? Colors.yellow : Colors.color5A5957,
          }}
          LinkComponent={NextLink}
          href={'/search'}
        >
          {t('search')}
        </Button>
        <Button
          variant='text'
          startIcon={<SvgAsset asset='playlist' />}
          sx={{
            color: pathname?.startsWith('/library') ? Colors.yellow : Colors.color5A5957,
          }}
          LinkComponent={NextLink}
          href={'/library'}
        >
          {t('playlist')}
        </Button>
      </Box>
      {loginState == 'notSignedIn' ? (
        <>
          <Button
            variant='outlined'
            href={'/login'}
            LinkComponent={NextLink}
            sx={{ width: '120px' }}
          >
            {t('login')}
          </Button>
          <SvgIcon
            component={SettingsOutlined}
            sx={{
              color: Colors.color5A5957,
              marginLeft: '12px',
              cursor: 'pointer',
            }}
            onClick={() => void push('/setting')}
          />
        </>
      ) : (
        <Box
          sx={{
            width: '120px',
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <UserAvatar avatarUrl={avatarUrl} />
        </Box>
      )}
    </Toolbar>
  )
}
