'use client'

import { AppBar, Container, Hidden } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import useSWR from 'swr'
import { useInitialAccessToken } from '~/features/auth/hooks/useInitialAccessToken'
import { useAddLocaleParams } from '~/features/i18n/hooks/useAddLocaleParams'
import { useAxios } from '~/libs/axios'
import { accessTokenState, userLoginState } from '~/recoil/auth'
import { Profile } from '~/types/api/users'
import { MobileHeaderContent } from './HeaderContent/MobileHeaderContent'
import { PcHeaderContent } from './HeaderContent/PcHeaderContent'

export type TitleI18nKey =
  | 'terms'
  | 'search'
  | 'my_profile'
  | 'app_settings'
  | 'language_setting'
  | 'to_all_creators'
  | 'home'
  | 'playlist'
  | 'create_profile'
  | 'account_recovery'
  | 'change_payment_method'
  | 'payment_method'
  | 'register_payment_method'
  | 'change_coupon'
  | 'contact'
  | 'privacy_policy'
  | 'withdrawal'
  | 'edit_profile'
  | 'auto_play_setting'

export interface AppHeaderProps {
  titleI18nKey?: TitleI18nKey
  title?: string
  /** モバイル時にヘッダーの左側に表示するコンテンツ */
  mobileLeftContent?: ReactNode
  /** モバイル時にヘッダーの右側に表示するコンテンツ */
  mobileRightContent?: ReactNode
}

export function AppHeader({
  titleI18nKey,
  title,
  mobileLeftContent,
  mobileRightContent,
}: AppHeaderProps) {
  const axios = useAxios()
  const localeParams = useAddLocaleParams()
  const accessToken = useRecoilValue(accessTokenState)
  const initialAccessTokenWorking = useInitialAccessToken()

  const { t } = useTranslation()
  const titleText = titleI18nKey ? t(titleI18nKey) : title

  const { data: profile, error: profileError } = useSWR<Profile>(
    initialAccessTokenWorking === 'done' && accessToken !== undefined
      ? `/v2/user/edit?${localeParams}`
      : null,
    (args) => axios.get(args).then((res) => res.data),
  )

  const loginState = useRecoilValue(userLoginState)

  return (
    <AppBar sx={{ backgroundColor: 'black' }}>
      {titleText && <title>{`${titleText} - SAMANSA`}</title>}
      {titleText && <meta property='og:title' content={`${titleText} - SAMANSA`} />}
      {titleText && <meta property='og:site_name' content={`${titleText} - SAMANSA`} />}
      <Container maxWidth='xl' sx={{ position: 'relative' }}>
        <Hidden smDown>
          <PcHeaderContent loginState={loginState} avatarUrl={profile?.avatar_url || ''} />
        </Hidden>
        <Hidden smUp>
          <MobileHeaderContent
            title={titleText ?? ''}
            rightContent={mobileRightContent}
            leftContent={mobileLeftContent}
          />
        </Hidden>
      </Container>
    </AppBar>
  )
}
