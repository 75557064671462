import { Box, Toolbar, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { Colors } from '~/libs/mui/theme'

interface MobileHeaderContentProps {
  title: string
  rightContent?: ReactNode
  leftContent?: ReactNode
}

export function MobileHeaderContent({
  title,
  leftContent,
  rightContent,
}: MobileHeaderContentProps) {
  return (
    <Toolbar
      disableGutters
      sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}
    >
      {/** leftContent */}
      {leftContent && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            my: 'auto',
            left: 16,
          }}
        >
          {leftContent}
        </Box>
      )}
      <Typography variant='h1' color={Colors.yellow}>
        {title}
      </Typography>
      {rightContent && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            right: 16,
          }}
        >
          {rightContent}
        </Box>
      )}
    </Toolbar>
  )
}
