import { type SxProps, type Theme } from '@mui/material'

/**
 * VideoItemCard の横幅・縦幅を決める関数
 * モバイル - 横幅: 108px, 縦幅: 160px (縦サムネ)
 * タブレット・PC - 横幅: 224px, 縦幅: 126px (横サムネ)
 *
 * @param fullWidth trueの場合は固定時の比率をもとに高さを算出する
 */
export const getSizeStyle = (fullWidth: boolean | undefined = false): SxProps<Theme> => {
  // 動的に決める場合
  if (fullWidth) {
    return {
      width: 1,
      //   aspectRatio: { xs: '160 / 108', sm: '126 / 224' },
      aspectRatio: { xs: '108 / 160', sm: '224 / 126' },
    }
  } else {
    // 固定
    return {
      width: { xs: 108, sm: 224 },
      height: { xs: 160, sm: 126 },
    }
  }
}
