import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SettingsIcon from '@mui/icons-material/Settings'
import { Avatar, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { useRouter } from 'next/navigation'
import { useState } from 'react'
import { Colors } from '~/libs/mui/theme'

interface UserAvatarProps {
  avatarUrl: string
}

export function UserAvatar({ avatarUrl }: UserAvatarProps) {
  const { push } = useRouter()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar alt='avatar' src={avatarUrl} />
      </IconButton>
      <Menu
        aria-labelledby='positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { backgroundColor: Colors.color23221F },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            void push('/profile')
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon sx={{ color: Colors.color5A5957 }} />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            void push('/setting')
          }}
        >
          <ListItemIcon>
            <SettingsIcon sx={{ color: Colors.color5A5957 }} />
          </ListItemIcon>
          <ListItemText>Setting</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
