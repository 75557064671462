interface Time {
  minutes: number | undefined
  seconds: number | undefined
}

/**
 *
 * @param videoTime  動画の再生時間
 * @param watchedTime ユーザの視聴済み時間
 */
export function getVideoWatchedPercent(videoTime: Time, watchedTime: Time): number | undefined {
  const videoTimeBySeconds = 60 * (videoTime.minutes ?? 0) + (videoTime.seconds ?? 0)
  const watchTimeBySeconds = 60 * (watchedTime.minutes ?? 0) + (watchedTime.seconds ?? 0)

  //　データの不整合で視聴時間が再生時間よりケースと0で除算ケースをカバー
  if (watchTimeBySeconds > videoTimeBySeconds || videoTimeBySeconds === 0) {
    return undefined
  }

  if (watchTimeBySeconds === 0) {
    return undefined
  }

  return watchTimeBySeconds / videoTimeBySeconds
}
