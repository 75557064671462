import { Box, Typography } from '@mui/material'
import { formatDuration } from '~/libs/format'

interface DurationLabelProps {
  durationSecond?: number
  durationMinute?: number
}

export function DurationLabel({ durationMinute, durationSecond }: DurationLabelProps): JSX.Element {
  return (
    <Box>
      <Typography
        fontSize='11px'
        sx={{
          backgroundColor: 'rgba(13,13,14,.6)',
          padding: '0px 4px',
          borderRadius: '4px',
          display: 'inline',
        }}
      >
        {formatDuration(durationMinute, durationSecond)}
      </Typography>
    </Box>
  )
}
