import { Box, Card } from '@mui/material'
import Image from 'next/image'
import { useMemo } from 'react'
import { Video } from '~/__generated__/graphql'
import { DurationLabel } from '~/components/DurationLabel'
import { UpComingChip } from '~/features/play_list/components/chips/UpComingChip'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import { thumbnailPlaceholder } from '~/libs/constants'
import { Colors } from '~/libs/mui/theme'
import { getVideoWatchedPercent } from '~/libs/video/getVideoWatchedPercent'
import { getSizeStyle } from './getSizeStyle'

interface VideoItemCardProps {
  video: Video
  fullWidth?: boolean
  onClickCard: () => void
}

export function VideoItemCard({
  video,
  fullWidth = false,
  onClickCard,
}: VideoItemCardProps): JSX.Element {
  const { isSm } = useMediaQuery()
  const sizeStyle = useMemo(() => getSizeStyle(fullWidth), [fullWidth])
  const watchedPercent = useMemo(() => {
    if (!video.viewingTime) return undefined
    return getVideoWatchedPercent(
      { minutes: video.duration?.minutes ?? 0, seconds: video.duration?.seconds ?? 0 },
      { minutes: video.viewingTime.viewingMin ?? 0, seconds: video.viewingTime.viewingSecond ?? 0 },
    )
  }, [video])
  const isUpComing = video.status === 'upcoming'
  return (
    <Card
      sx={{
        position: 'relative',
        cursor: 'pointer',
        ...sizeStyle,
      }}
      onClick={onClickCard}
    >
      <Image
        alt='thumbnail'
        src={isSm ? video.landscapeThumbnail ?? '' : video.portraitThumbnail ?? ''}
        fill
        style={{
          objectFit: 'cover',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
        placeholder='blur'
        blurDataURL={thumbnailPlaceholder}
      />
      {isUpComing && <UpComingChip />}
      <Box position='absolute' top={2} right={2}>
        <DurationLabel
          durationMinute={video.duration?.minutes ?? 0}
          durationSecond={video.duration?.seconds ?? 0}
        />
      </Box>
      {!!watchedPercent && (
        <Box
          position='absolute'
          bottom={0}
          left={0}
          height='3px'
          width={`${watchedPercent * 100}%`}
          sx={{ backgroundColor: Colors.red, borderRadius: '1px' }}
        />
      )}
    </Card>
  )
}
